
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "profile",
  setup() {
    const store = useStore();

    return {
      userDetail: computed(() => store.getters.currentUser),
    };
  },
  components: {
    Dropdown3,
  },
});
